import axios from "axios";
import qs from'qs';
const active = {
    rankFree(data) {
    return axios.get("/api/ppy/usercenter/bwcproj/rankFree",{params:data});
  },
  orderSubmit(data) {
    return axios.post("/api/ppy/usercenter/bwcproj/orderSubmit",qs.stringify(data));
  },
  rankFreeUser() {
    return axios.get("/api/ppy/usercenter/bwcproj/rankFreeUser");
  },
  rankList(data) {
    return axios.get("/api/ppy/usercenter/bwcproj/rankList",{params:data});
  },
  teamMyTeam() {
    return axios.get("/api/ppy/usercenter/bwcproj/teamMyTeam");
  },
  ticketIssue() {
    return axios.get("/api/ppy/usercenter/bwcproj/ticketIssue");
  },
  ticketMyPrevious() {
    return axios.get("/api/ppy/usercenter/bwcproj/ticketMyTicket");
  },
  ticketMyTicket() {
    return axios.get("/api/ppy/usercenter/bwcproj/ticketMyPrevious");
  },
  ticketDrawMyPrevios() {
    return axios.get("/api/ppy/usercenter/bwcproj/ticketDrawMyPrevios");
  },
};
export default active;
