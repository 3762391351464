<template>
  <div class="ccontent">
    <div class="ccontent-go">
      <Banner :banner="rankUserlist"></Banner>
    </div>
    <div class="rule" @click="rule">活动规则</div>
    <!-- <div class="detailed rule">参与明细</div> -->

    <!-- 奖池 -->
    <div class="Jackpot">
      <div class="Jackpot-title"><span>当前奖券数：</span>{{actConfigs.ticketCounts}}</div>
      <div class="Jackpot-money">
        <div>¥</div>
        <div v-for="(item,index) in Array.from(String(Math.trunc(actConfigs.poolMoney)))" :key="index">
          <p></p>
          <div>{{item}}</div>
        </div>
        
      </div>
      <div class="Jackpot-five">大奖：{{actConfigs.freeCounts}}个免单奖</div>
      <img
        src="https://ppyos.xijiuyou.com/bwc2206/award-pool.png"
        alt=""
      />
      <div class="Jackpot-lottery" v-if="tickets.hasTicket">
        <p>我的待开奖券</p>
        <div class="Coupons">
          <div v-for="(item , index) in tickets.tickets" :key="index">
            <img
              :src="item.ticketType===0?'https://ppyos.xijiuyou.com/bwc2206/elm-icon.jpg':'https://ppyos.xijiuyou.com/bwc2206/elm-icon.jpg'"
              alt=""
            />
            <div>
              <p>{{item.ticketNum}}</p>
              <p>待开奖</p>
            </div>
          </div>
        </div>
        <p class="lottery">{{awardCountDown}}后开奖</p>
      </div>
    </div>

    <!-- 免单 -->
    <div class="order">
      <div class="order-top">
        <div class="order-top-mian">
          <img
            class="process"
            src="https://ppyos.xijiuyou.com/bwc2206/draw-banner.png"
            alt=""
          />
          <div class="order-top-mian-dingd">
            <div v-for="(item,index) in actConfigs.actConfigs" :key="index">
              <img
                :src="item.logo"
                alt=""
              />
              <p>{{item.name}}</p>
            </div>
            <div @click="orders">已下单，提交单号</div>
          </div>
          <div class="technological">
            <p>①领券下单 ②提交单号获得奖券 ③10:30准时开奖</p>
            <p @click="isshowpopup">（如何找到订单号？)</p>
          </div>
        </div>
      </div>
      <div class="list-arr">
        <p class="list-arr-title">一人中奖全队免单 队伍人多机会越大</p>
        <div class="list-arr-list">
          <div class="list-arr-list-img" v-for="(item,index) in members" :key="index">
            <div>
              <img
                :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'"
                alt=""
              />
              <p v-if="item.ordered">已下单</p>
            </div>
            <p class="list-arr-list-name">{{item.nickname}}</p>
          </div>
          <div class="list-arr-list-img" v-for="item in 5-members.length" :key="item">
            <div class="list-arr-list-img-document">
              <img
                class="document"
                src="https://ppyos.xijiuyou.com/bwc2206/add-icon.png"
                alt=""
              />
            </div>
            <p class="list-arr-list-name list-arr-list-color">虚位以待</p>
          </div>
        </div>
        <div class="explain">
          <p>一人中免单奖，全队已下单队友都免单</p>
          <p>*队伍当期有效，每日开奖后自动解散</p>
        </div>
        <div class="list-arr-btn">邀好友组队</div>
      </div>
    </div>

    <!-- 新人福利 -->
    <div class="welfare">
      <div>新人福利</div>
      <div>新人关注服务号：{{actConfigs.serviceAccount}}</div>
      <div>
        参与前{{actConfigs.newerIssueCount}}期活动，开奖时将额外获得至少包含<span>{{actConfigs.newerIssueMoney}}元微信红包</span>的奖励（每期一次，中免单奖时除外）
      </div>
    </div>

    <!-- 昨日免单英雄榜 -->
    <div class="hero">
      <div class="hero-period" @click="periodup" v-if="hasPre">上一期</div>
      <div class="hero-periods" @click="periodout"  v-if="haxNext">下一期</div>
      <div class="hero-title">昨日免单英雄榜</div>
      <div class="hero-mian">
        <img
          class="hero-mian-img"
          src="https://ppyos.xijiuyou.com/bwc2206/md-coupe.png"
          alt=""
        />
        <div class="hero-mian-list" v-for="(item,index) in teams" :key="index">
          <div v-for="(items,index_) in item" :key="index_">
            <div>
              <img
                :src="items.avatar"
                alt=""
              />
            </div>
            <p>{{items.nickname}}</p>
          </div>
        </div>
      </div>
      <div class="jaing-title">昨日瓜分奖金榜</div>
      <div class="jaing-money">瓜分金额：<span>￥356.79</span></div>
      <div class="Partition">
        <div>
          <span>{{myRanking.ranking}}</span>
          <img :src="myRanking.avatar?myRanking.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'" alt="">
          <span>{{myRanking.nickname}}(我)</span>
        </div>
        <div>
          <img src="https://ppyos.xijiuyou.com/bwc2206/coupe-icon.png" alt="">
          <span>{{myRanking.tickets?myRanking.tickets:0}}</span>
        </div>
        <div>
          <p class="topmoney">{{myRanking.ticketAward?'免单奖':''}}<span>{{myRanking.ticketAward?myRanking.ticketAward:'未上榜'}}</span>{{myRanking.ticketAward?'元':''}}</p>
          <p><span>+{{myRanking.ticketAward}}</span>元</p>
        </div>
      </div>
      <div class="Partition-list" v-for="(item,index) in rankingList" :key="index">
        <div>
          <span>{{item.ranking}}</span>
          <img :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'"  alt="">
          <span>{{item.nickname}}</span>
        </div>
        <div>
          <img src="https://ppyos.xijiuyou.com/bwc2206/coupe-icon.png" alt="">
          <span>{{item.tickets}}</span>
        </div>
        <div>
          <p><span>+{{item.ticketAward}}</span>元</p>
        </div>
      </div>
    </div>


    <!-- 弹窗 -->
    <notification @Submitss="Submitss" :Submits="Submits" @invalids="invalids" :invalid="invalid" :order="order" @orders="orders" :rule="rules" @rules="rule" :isshowpopup="isshowpopups" @isshowpopups="isshowpopup"></notification>
  </div>
</template>
<script>
import Banner from "@/components/Banner.vue"
import notification from "@/components/notification.vue"
import bwc from "../../api/bwccontroller"
export default {
  components: {
       Banner,
       notification
  },
  data() {
    return{
      rules:false,
      isshowpopups:false,
      order:false,
      invalid:false,
      Submits:false,
      teams:[],
      rankUserlist:[],
      myRanking:{},
      rankingList:[],
      members:[],
      actConfigs:{},
      tickets:[],
      awardCountDown:'',
      interval:-1,
      hasPre:true,
      haxNext:true,
    }
  },
  mounted(){
    this.rankFree();
    this.rankFreeUser();
    this.rankLists();
    this.teamMyTeam();
    this.ticketIssue();
    this.ticketMyPrevious();
  },
  methods:{
    periodup(){
      this.interval--;
      this.rankLists();
      this.rankFree();
    },
    periodout(){
      this.interval++;
      this.rankLists();
      this.rankFree();
    },
    ticketMyPrevious(){
      bwc.ticketMyPrevious().then(res=>{
        this.tickets = res.data;
        // eslint-disable-next-line no-unused-vars
       // let times = res.data.awardCountDown
        setInterval(()=>{
          // this.awardCountDown--
          res.data.awardCountDown--
          this.awardCountDown = Math.trunc(res.data.awardCountDown/3600)+":"+Math.trunc((res.data.awardCountDown%3600)/60)+":"+(res.data.awardCountDown%3600)%60
        },1000)
      })
    },
    ticketIssue(){
      bwc.ticketIssue().then(res=>{
        this.actConfigs = res.data;
      })
    },
    teamMyTeam(){
      bwc.teamMyTeam().then(res=>{
        this.members = res.data.members;
      })
    },
    rankLists(){
      bwc.rankList({interval:this.interval,top:10}).then(res=>{
        this.rankingList = res.data.rankingList;
        this.myRanking = res.data.myRanking;
        this.hasPre = res.data.hasPre;
        this.haxNext = res.data.haxNext;
      })
    },
    rankFreeUser(){
      bwc.rankFreeUser().then(res=>{
        this.rankUserlist = res.data
      })
    },
    rankFree(){
      bwc.rankFree({top:10,interval: this.interval}).then(res=>{
        this.teams = res.data.teams;
      })
    },
    Submitss(){
      this.Submits = !this.Submits;
      this.rankFree();
      this.rankFreeUser();
      this.rankLists();
      this.teamMyTeam();
      this.ticketIssue();
      this.ticketMyPrevious();
    },
    invalids(){
      this.invalid = !this.invalid;
    },
    orders(){
      this.order = !this.order;
    },
    isshowpopup(){
      this.isshowpopups = !this.isshowpopups;
    },
    rule(){
      this.rules = !this.rules;
    },
  }
}
</script>
<style lang="less" scoped>
.ccontent {
  width: 100%;
  min-height: 100vh;
  background: url("https://ppyos.xijiuyou.com/bwc2206/bwc-bg.png")
    no-repeat;
  background-size: 100%;
  background-color: #67a8ff;
  padding-top: 1px;
  padding-bottom: 34px;
  .rule {
    background: #444444;
    border-radius: 72px 0px 0px 72px;
    width: 64px;
    padding: 5px 0;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    right: 0;
    text-align: center;
    top: 115px;
  }
  .detailed {
    top: 157px;
  }
  .ccontent-go {
    height: 32px;
    margin-top: 23px;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  .Jackpot {
    margin-top: 94px;
    width: 100%;
    position: relative;
    padding-top: 50px;
    margin-bottom: 25px;
    img {
      width: 100%;
      position: relative;
    }
    .Jackpot-title {
      position: absolute;
      z-index: 1;
      width: 147px;
      background: linear-gradient(270deg, #e94141 0%, #fa6767 100%);
      border-radius: 14px;
      padding: 6px 0;
      text-align: center;
      font-size: 14px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #ffffff;
      margin: 0 auto;
      left: calc(50% - 73px);
      top: 105px;
      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .Jackpot-money {
      position: absolute;
      z-index: 1;
      left: calc(50% - 130px);
      top: 170px;
      display: flex;
      width: 260px;
      margin: 0 auto;
      height: 70px;
      font-size: 36px;
      font-family: DINOffcPro-Bold, DINOffcPro;
      font-weight: bold;
      color: #ff4d4d;
      align-items: center;
      justify-content: center;
      div{
        display: flex;
        align-items: center;
        p{
          width: 0;
          height: 35px;
          border: 1px solid;
          border-image: linear-gradient(180deg,
              rgba(255, 170, 170, 0),
              rgba(255, 143, 143, 1),
              rgba(255, 170, 170, 0)) 1 1;
          margin: 0 10px;
        }
      }
    }
    .Jackpot-five {
      position: absolute;
      z-index: 1;
      left: calc(50% - 65px);
      top: 250px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }
    .Jackpot-lottery {
      width: calc(100% - 24px);
      margin: -15px auto 0;
      height: 240px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.5) 0%,
        #ffffff 100%
      );
      border-radius: 0px 0px 15px 15px;
      text-align: center;
      padding-top: 28px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #444444;
      .lottery {
        width: 201px;
        background: #cccccc;
        border-radius: 24px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        padding: 13px 0;
        margin: 20px auto 0;
      }
      .Coupons {
        height: 83px;
        overflow: scroll;
        display: flex;
        padding-right: 14px;
        margin-top: 14px;
        div {
          width: 223px;
          height: 83px;
          background: linear-gradient(90deg, #ff9a68 0%, #ff4667 100%);
          background: url("https://ppyos.xijiuyou.com/bwc2206/ticket-bg.jpg")
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          margin-left: 14px;
          align-items: center;
          padding: 0 19px;
          img {
            width: 40px;
            height: 40px;
            margin-right: 36px;
          }
          div {
            flex-wrap: wrap;
            background: rgba(0, 0, 0, 0);
            margin: 0;
            padding: 0;
            width: 100%;
            display: block;
            height: auto;
            text-align: left;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #d30013;
            p {
              font-size: 16px;
              font-family: DINOffcPro-Medi, DINOffcPro;
              font-weight: normal;
              color: #d30013;
            }
            p:nth-child(2) {
              width: 111px;
              padding: 3px auto;
              background: rgba(255, 255, 255, 0.5);
              border-radius: 12px;
              text-align: center;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .order {
    width: calc(100% - 24px);
    margin: 0 auto;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      #ffffff 100%
    );
    border-radius: 15px;
    .order-top {
      width: 100%;
      height: 186px;
      background: linear-gradient(180deg, #fff5e6 0%, #ffe8c5 100%);
      border-radius: 20px;
      padding: 3px;
      .order-top-mian {
        width: 100%;
        height: 180px;
        border-radius: 17px;
        border: 1px solid rgba(255, 208, 158, 0.52);
        text-align: center;
        .process {
          width: 181px;
          margin: -18px auto 16px;
        }
        .order-top-mian-dingd {
          display: flex;
          align-items: center;
          justify-content: space-around;
          div:nth-child(1) {
            width: 74px;
            height: 88px;
            background: url("https://ppyos.xijiuyou.com/bwc2206/redpack-icon.png")
              no-repeat;
            background-size: 100% 100%;
            padding-top: 15px;
          }
          div:nth-child(2) {
            padding-top: 15px;
            width: 74px;
            height: 88px;
            background: url("https://ppyos.xijiuyou.com/bwc2206/redpack-icon.png")
              no-repeat;
            background-size: 100% 100%;
          }
          div:nth-child(3) {
            width: 154px;
            height: 50px;
            background: url("https://ppyos.xijiuyou.com/bwc2206/banner-1.png")
              no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          div {
            p {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
            img {
              width: 40px;
            }
          }
        }
        .technological {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          margin-top: 14px;
          p:nth-child(2) {
            color: rgba(0, 123, 255, 1);
          }
        }
      }
    }
    .list-arr {
      padding: 19px 0 38px;
      text-align: center;
      .list-arr-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #444444;
        margin-bottom: 19px;
      }
      .explain {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #007bff;
        margin-top: 20px;
        margin-bottom: 18px;
        p:nth-child(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
        }
      }
      .list-arr-btn {
        width: 224px;
        height: 50px;
        background: url("https://ppyos.xijiuyou.com/bwc2206/banner-2.png")
          no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 0;
      }
      .list-arr-list {
        display: flex;
        justify-content: space-between;
        padding: 0 23px;
        .list-arr-list-img {
          .list-arr-list-img-document {
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .document {
            width: 18px;
            height: 18px;
          }
          div {
            width: 44px;
            height: 44px;
            border-radius: 18px;
            border: 2px solid #ffffff;
            img {
              width: 100%;
              border-radius: 100%;
            }
            p {
              width: 38px;
              height: 16px;
              background: rgba(255, 255, 255, 0.8);
              border-radius: 6px;
              backdrop-filter: blur(4px);
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #007bff;
              margin-top: -10px;
            }
          }
          .list-arr-list-name {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            margin-top: 9px;
          }
          .list-arr-list-color {
            color: #999999;
          }
        }
      }
    }
  }
  .welfare {
    width: calc(100% - 24px);
    margin: 10px auto;
    height: 171px;
    background: #ffffff;
    border-radius: 15px;
    text-align: center;
    div:nth-child(1) {
      width: 183px;
      height: 31px;
      background: url("https://ppyos.xijiuyou.com/bwc2206/banner-3.png")
        no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding-top: 3px;
    }
    div:nth-child(2) {
      margin: 0 auto;
      margin-top: 10px;
      width: 241px;
      padding: 7px 0;
      background: rgba(0, 123, 255, 0.15);
      border-radius: 6px;
      font-size: 16px;
      font-family: AlibabaPuHuiTiB;
      color: rgba(0, 123, 255, 1);
      margin-bottom: 17px;
    }
    div:nth-child(3) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      padding: 0 18px;
      span {
        color: rgba(255, 44, 92, 1);
      }
    }
  }
  .hero {
    width: calc(100% - 24px);
    margin: 0 auto;
    padding-top: 18px;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    .hero-period {
      position: absolute;
      width: 60px;
      background: linear-gradient(180deg, #fff5e6 0%, #ffe8c5 100%);
      border-radius: 14px;
      padding: 5px 0;
      left: 25px;
      top: 18px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f4331;
      text-align: center;
    }
    .hero-periods{
      position: absolute;
      width: 60px;
      background: linear-gradient(180deg, #fff5e6 0%, #ffe8c5 100%);
      border-radius: 14px;
      padding: 5px 0;
      right: 25px;
      top: 18px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8f4331;
      text-align: center;
    }
    .hero-title {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #444444;
    }
    .hero-mian {
      width: calc(100% - 24px);
      background: linear-gradient(180deg, #fff5e6 0%, #ffe8c5 100%);
      border-radius: 15px;
      margin: 26px auto 0;
      position: relative;
      padding: 19px 10px 19px 19px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .hero-mian-img {
        position: absolute;
        width: 53px;
        left: -12px;
        top: -17px;
      }
    }
    .hero-mian-lists {
      background: rgba(0, 0, 0, 0);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 5px;
      p {
        margin-top: 4px;
      }
      div {
        width: 47px;
        height: 47px;
        img {
          width: 100%;
          border-radius: 18px;
        }
      }
    }
    .hero-mian-list {
      margin-bottom: 5px;
      display: inline-block;
      height: 50px;
      background: #ffccaa;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      margin-left: 22px;
      margin-right: 22px;
      div:nth-child(1) {
        margin-left: -22px;
      }
      div:last-child {
        margin-right: -22px;
      }
      div {
        float: left;
        margin: 0 8px;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          text-align: center;
        }
        div {
          margin: 0 !important;
          width: 50px;
          height: 50px;
          background: #ffffff;
          border-radius: 18px;
          border: 3px solid rgba(255, 127, 75, 1);
          img {
            width: 100%;
            border-radius: 15px;
          }
        }
      }
    }
    .jaing-title{
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #444444;
      text-align: center;
      margin-top: 18px;
    }
    .jaing-money{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #007BFF;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 14px;
      span{
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #007BFF;
      }
    }
    .Partition-list{
      display: flex;
      justify-content: space-between;
      width: calc(100% - 4px);
      margin: 0 auto;
      height: 60px;
      border-radius: 4px;
      align-items: center;
      div:nth-child(1){
        display: flex;
        align-items: center;
        padding-left: 6px;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #444444;
        width: 140px;
        img{
          width: 40px;
          border-radius: 50px;
          margin: 0 10px;
        }
        span:nth-child(1){
          width: 25px;
          text-align: center;
        }
        span:nth-child(3){
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
        }
      }
       div:nth-child(2){
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #444444;
        img{
          width: 16px;
          margin-right: 6px;
        }
      }
      div:nth-child(3){
        padding-right: 2px;
        width: 140px;
        .topmoney{
          // width: 102px;
          background: #FFFFFF;
          border-radius: 10px 0px 0px 10px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #444444;
          text-align: center;
          padding: 0;
          span{
            font-size: 16px;
          }
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(68, 68, 68, 1);
          text-align: right;
          padding-right: 10px;
          span{
            font-size: 16px;
          }
        }
      }
    }
    .Partition{
      display: flex;
      justify-content: space-between;
      width: calc(100% - 4px);
      margin: 0 auto;
      height: 60px;
      background: rgba(255, 86, 117, 0.12);
      border-radius: 4px;
      align-items: center;
      div:nth-child(1){
        display: flex;
        align-items: center;
        padding-left: 6px;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #444444;
        width: 140px;
        img{
          width: 40px;
          border-radius: 50px;
          margin: 0 10px;
        }
        span:nth-child(1){
          width: 25px;
          text-align: center;
        }
        span:nth-child(3){
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF2C5C;
        }
      }
       div:nth-child(2){
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #FF2C5C;
        img{
          width: 16px;
          margin-right: 6px;
        }
      }
      div:nth-child(3){
        padding-right: 2px;
        width: 140px;
        text-align: right;
        .topmoney{
          // width: 102px;
          background: #FFFFFF;
          border-radius: 10px 0px 0px 10px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF2C5C;
          text-align: center;
          padding: 0 8px;
          display: inline-block;
          span{
            font-size: 16px;
          }
        }
        p{
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF2C5C;
          text-align: right;
          padding-right: 10px;
          span{
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
