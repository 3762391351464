<template>
  <div>
    <!-- 如何找到订单 -->
    <xwpopup
      :isshowpopup="isshowpopup"
      @close="closeHandle()"
      :hiddenClose="false"
    >
      <div class="dingd">
        <p>如何找到订单号</p>
        <p>点击订单，在订单信息一栏可以看到订单号码直接复制</p>
        <p>饿了么订单</p>
        <img
          src="https://ppyos.xijiuyou.com/bwc2206/guide-success.png"
          alt=""
        />
        <p>美团订单</p>
        <img
          src="https://ppyos.xijiuyou.com/bwc2206/guide-order.png"
          alt=""
        />
      </div>
    </xwpopup>

    <!-- 提交订单号 -->
    <xwpopup :isshowpopup="order" @close="closeorder()" :hiddenClose="false">
      <div class="order">
        <p>提交订单号</p>
        <p>请提交订单号（点开订单详情，找到订单号)</p>
        <div>
          <input type="text" v-model="orderSubmits" />
          <span>粘贴</span>
        </div>
        <div>
          <button @click="closeorder()">取消</button>
          <button @click="orderSubmit">提交</button>
        </div>
      </div>
    </xwpopup>

    <!-- 订单未找到 -->
    <xwpopup
      :isshowpopup="invalid"
      @close="closeinvalid()"
      :hiddenClose="false"
    >
      <div class="invalid">
        <p>{{succ.tips}}</p>
        <p>{{succ.note}}</p>
        <div @click="closeinvalid">好的</div>
      </div>
    </xwpopup>

    <!-- 提交成功 -->
    <xwpopup
      :isshowpopup="Submits"
      @close="closeSubmits()"
      :hiddenClose="false"
    >
      <div class="Submits">
        <img
          class="head"
          src="https://ppyos.xijiuyou.com/bwc2206/submit-success.png"
          alt=""
        />
        <p>{{succ.note}}</p>
        <p></p>
        <div class="imgk">
          <img
            :src="succ.ticketType==1?'https://ppyos.xijiuyou.com/bwc2206/elm-icon.jpg':'https://ppyos.xijiuyou.com/bwc2206/elm-icon.jpg'"
            alt=""
          />
          <div>
            <div>{{succ.ticketNum}}</div>
            <div>待开奖</div>
          </div>
        </div>
        <p>您可以邀请好友组队，增加免单机会（一人中奖，全队免单）</p>
        <p @click="closeSubmits">收下了</p>
      </div>
    </xwpopup>

    <!-- 规则 -->
    <xwpopup :isshowpopup="rule" @close="closerules()" :hiddenClose="false">
      <div class="rules">
        <img
          src="https://ppyos.xijiuyou.com/bwc2206/guide-rule-banner.png"
          alt=""
        />
        <div></div>
        <p>
          1.
          每天活动奖金会随着用户下单参与而逐渐增加，每期的具体时间是每日的10:30至次日的10:29；
        </p>
        <p>
          2.
          在本页面通过饿了么、美团等平台下单后，并提交订单号，可以领取参与奖券，在下个10:30参与开奖（10点后的订单次日开奖），多下单多得奖券；
        </p>
        <p>
          3.
          提交订单号需真实有效，并在当前期的时间范围之内（每日的10:00至次日的10:00）,10点后的订单需要在次日的10:30开奖；如存在订单号未找到或无效的情况，请取消订单重新下单；退款、取消等异常订单的奖券将失效或停发奖金；
        </p>
        <p>
          4.
          组队更容易中免单奖，一人中免单奖，则全队本期已下单的用户都中免单奖；队伍仅当期有效，开奖后将自动解散，需要重新组队；
        </p>
        <p>5. 免单奖取当前奖券的订单的结算金额，超过30元的按30元计算；</p>
        <p>6. 本次活动的最终解释权归米粒饭票所有</p>
      </div>
    </xwpopup>

    <xwpopup :isshowpopup="single" @close="closesingle()" :hiddenClose="false">
      <div class="single" v-if="myPrev[index]">
        <img src="https://ppyos.xijiuyou.com/bwc2206/award-red-icon.jpg" />
        <div>
          <img  v-if="myPrev[index]" :src="myPrev[index].awardType ===1 || myPrev[index].awardType ===3?'https://ppyos.xijiuyou.com/bwc2206/ticket-yellow-icon.jpg':'https://ppyos.xijiuyou.com/bwc2206/freeaward-red-icon.jpg'" />
          <span v-if="myPrev[index]">¥{{myPrev[index].awardType ===1 || myPrev[index].awardType ===3?myPrev[index].ticketMoney:myPrev[index].freeMoney}}</span>
        </div>
        <div  class="gilon" v-if="(myPrev[index].awardType ===3 || myPrev[index].awardType ===6) && myPrev[index]">+</div>
        <div class="gilongs" v-if="(myPrev[index].awardType ===3 || myPrev[index].awardType ===6)">
          <p>额外奖励：</p>
          <img src="https://ppyos.xijiuyou.com/bwc2206/wechat-red-icon.jpg" />
          <span v-if="myPrev[index]">{{myPrev[index].newerMoney}}元微信红包</span>
        </div>
        <div class="gilong" @click="nextstep">{{index == myPrev.length-1?'收下了':'开下一张'}}</div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import xwpopup from "../components/Popupbox.vue";
import bwc from "../api/bwccontroller"
export default {
  components: {
    xwpopup,
  },
  props:{
    rule:{
      type:Boolean,
      default: false,
    },
    isshowpopup:{
      type:Boolean,
      default: false,
    },
    isshowpopups:{
      type:Function
    },
    rules:{
      type:Function
    },
    order:{
      type:Boolean,
      default: false,
    },
    orders:{
      type:Function
    },
    invalids:{
      type:Function
    },
    invalid:{
      type:Boolean,
      default: false,
    },
    Submitss:{
      type:Function
    },
    Submits:{
      type:Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderSubmits:"",
      succ:{},
      single:false,
      myPrev:[],
      index:0,
    };
  },
  mounted(){
    // console.log(this.rule)
    this.ticketMyPrevious();
  },
  methods: {
    nextstep(){
      const that =this;
      that.index++
      if(that.index === that.myPrev.length){
        that.single = false;
      }
    },
    closesingle(){
      this.single = !this.single;
    },
    ticketMyPrevious(){
      const that = this;
      bwc.ticketMyTicket().then(res=>{
        that.myPrev=res.data.tickets;
        that.single = res.data.hasTicket;
        bwc.ticketDrawMyPrevios();
      })
    },
    orderSubmit(){
      bwc.orderSubmit({orderNo:this.orderSubmits}).then((res)=>{
        this.$emit("orders");
        this.orderSubmits = "";
        if(res.data.success){
          this.$emit("Submitss");
        }else{
          this.$emit("invalids");
        }
        this.succ = res.data
      })
    },
    closerules() {
      this.$emit("rules");
    },
    closeSubmits() {
      this.Submits = !this.Submits;
    },
    closeinvalid() {
      this.$emit("invalids");
    },
    closeorder() {
      this.$emit("orders");
    },
    closeHandle() {
      this.$emit("isshowpopups");
    },
  },
};
</script>

<style lang="less" scoped>
.dingd {
  margin: 64px auto 0;
  width: calc(100% - 70px);
  background: #ffffff;
  border-radius: 15px;
  padding-top: 18px;
  height: 500px;
  overflow: scroll;
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    margin-bottom: 18px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    margin-bottom: 18px;
    padding: 0 20px;
    text-align: left;
  }
  p:nth-child(3) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    margin-bottom: 10px;
  }
  img:nth-child(4) {
    width: 100%;
  }
  p:nth-child(5) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #444444;
    margin-bottom: 10px;
    margin-top: 24px;
  }
  img:nth-child(6) {
    width: 100%;
  }
}
.order {
  margin: 64px auto 0;
  width: calc(100% - 70px);
  background: #ffffff;
  border-radius: 15px;
  padding-top: 24px;
  padding-bottom: 32px;
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    margin-bottom: 18px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    margin-bottom: 24px;
  }
  div:nth-child(3) {
    width: calc(100% - 48px);
    height: 50px;
    border-radius: 8px;
    border: 1px solid #bbbbbb;
    margin: 0 auto 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    input {
      width: 100%;
      height: 100%;
      border: 0px;
    }
    span {
      display: block;
      width: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #007bff;
    }
  }
  div:nth-child(4) {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    button {
      width: 120px;
      height: 44px;
      background: #eeeeee;
      border-radius: 27px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #444444;
      border: 0;
    }
    button:nth-child(2) {
      background: linear-gradient(90deg, #ff9a68 0%, #ff4667 100%);
      color: #ffffff;
    }
  }
}
.invalid {
  margin: 200px auto 0;
  width: calc(100% - 70px);
  background: #ffffff;
  border-radius: 15px;
  padding-top: 24px;
  padding-bottom: 32px;
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #444444;
    margin-bottom: 18px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    margin-bottom: 24px;
  }
  div:nth-child(3) {
    width: 175px;
    height: 44px;
    border-radius: 27px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    border: 0;
    background: linear-gradient(90deg, #ff9a68 0%, #ff4667 100%);
    color: #ffffff;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.Submits {
  margin: 200px auto 0;
  width: 300px;
  background: #ffffff;
  border-radius: 15px;
  padding-top: 24px;
  padding-bottom: 32px;
  position: relative;
  .head {
    position: absolute;
    width: 100%;
    left: 0;
    top: -52px;
  }
  p:nth-child(2) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 25px;
  }
  p:nth-child(3) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 25px;
  }
  .imgk {
    width: 223px;
    height: 84px;
    background: url("https://ppyos.xijiuyou.com/bwc2206/ticket-bg.jpg")
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px;
    margin: 14px auto 24px;
    img {
      width: 40px;
      height: 40px;
    }
    div {
      div {
        font-size: 16px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #d30013;
      }
      div:nth-child(2) {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 12px;
        padding: 2px 0;
        margin-top: 8px;
      }
    }
  }
  p:nth-child(5) {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff5675;
    padding: 0 50px;
    margin-bottom: 15px;
  }
  p:nth-child(6) {
    width: 175px;
    height: 48px;
    background: linear-gradient(90deg, #ff9a68 0%, #ff4667 100%);
    border-radius: 24px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding: 14px 0;
    margin: 0 auto;
  }
}
.rules {
  width: calc(100% - 70px);
  margin: 50px auto 0;
  position: relative;
  background: #ffffff;
  border-radius: 15px;
  padding: 30px 20px 20px;
  max-height: 400px;
  overflow: scroll;
  img {
    width: 181px;
    height: 34px;
    position: fixed;
    left: calc(50% - 90px);
    top: 35px;
    z-index: 1;
  }
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    text-align: left;
    line-height: 25px;
  }
  div {
    position: fixed;
    width: calc(100% - 70px);
    height: 26px;
    background: linear-gradient(
      180deg,
      rgba(255, 174, 93, 0.36) 0%,
      rgba(255, 182, 110, 0) 100%
    );
    border-radius: 14px 14px 0px 0px;
    left: 35px;
    top: 51px;
  }
}



.single{
  width: 305px;
  background: #ffffff;
  margin: 100px auto 0;
  padding-bottom: 30px;
  border-radius: 15px;
  img:nth-child(1){
    width: 305px;
    height: 75px;
    margin-top: -52px;
  }
  div:nth-child(2){
    width: 257px;
    height: 88px;
    display: flex;
    background: linear-gradient(270deg, rgba(255, 157, 107, 0.23) 0%, rgba(255, 204, 164, 0.23) 100%);
    margin: 25px auto 0;
    border-radius: 10px;
    img{
      width: 155px;
      height: 88px;
      margin: 0;
    }
    span{
      display: block;
      text-align: center;
      line-height: 88px;
      font-size: 20px;
      font-family: DINOffcPro-Medi, DINOffcPro;
      font-weight: normal;
      color: #FF5675;
      width: calc(100% - 155px);
    }
  }
  .gilon{
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF5675;
  }
  .gilongs{
    width: 257px;
    height: 46px;
    display: flex;
    background: linear-gradient(270deg, rgba(255, 157, 107, 0.23) 0%, rgba(255, 204, 164, 0.23) 100%);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF5675;
    image{
      width: 16px;
      height: 14px;
      margin: 0;
    }
  }
  .gilong{
    width: 175px;
    height: 48px;
    background: linear-gradient(90deg, #FF9A68 0%, #FF4667 100%);
    border-radius: 24px;
    margin: 0 auto;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 24px;
  }
}
</style>