<template>
  <div class="list" :style="'margin-left:'+-going+'px;width:'+banner.length*265*2+'px;'">
    <div v-for="(item , index) in banner" :key="index">
      <img
        :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'"
        alt=""
      />
      <span>{{item.nickname}}用户获得免单奖</span>
    </div>
    <!-- <div v-for="(item , index) in banner" :key="index">
      <img
        :src="item.avatar?item.avatar:'https://ppyos.xijiuyou.com/202206/default_avatar.png'"
        alt=""
      />
      <span>{{item.nickname}}用户获得免单奖</span>
    </div> -->
  </div>
</template>
<script>
export default {
  props:{
    banner:{
      type:Array
    }
  },
    data() {
        return{
            going:0,
            time:null
        }
    },
    mounted(){
        const that =this;
        this.time = setInterval(()=>{
            if(that.going>135*this.banner.length){
                that.going = 0
            }
            that.going++
        },10)
    },
    destroyed(){
        clearInterval(this.time);
    }
}
</script>
<style lang="less" scoped>
.list {
  display: flex;
  div {
    height: 32px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 16px;
    display: flex;
    line-height: 32px;
    margin:0 40px;
    padding: 0 13px 0 0;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin: 4px 6px  0 4px;
    }
    span{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
    }
  }
}
</style>