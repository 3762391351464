<template>
  <div class="xw-earnings" v-if="isshowpopup">
    <slot class="slot"></slot>
    <img
      src="https://ppyos.xijiuyou.com/210916/close-btn.jpg"
      alt=""
      @click="closeHandle"
      :class="Postionclass"
      v-if="!hiddenClose"
    />
  </div>
</template>

<script>
export default {
  name: "Xwpopup",
  props: {
    isshowpopup: {
      type: Boolean,
      default: false,
    },
    isAutoClose: {
      type: Boolean,
      default: false,
    },
    hiddenClose: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 3000,
    },
    closePostion: {
      type: String,
      default: "bottom",
    },
    close: {
      type: Function,
    },
    cliseback:{
      type: Function,
    }
  },
  data() {
    return {
      setTimeoutId: "",
    };
  },
  watch: {
    isshowpopup: function (n) {
      if (this.isAutoClose && n) {
        this.setTimeoutId = setTimeout(() => {
          this.closeHandle();
        }, this.delay);
      }
      document.body.style.overflow = n ? "hidden" : "auto";
    },
  },
  computed: {
    Postionclass() {
      switch (this.closePostion) {
        case "bottom":
          return "close";
        case "top":
          return "topclose";
        default:
          break;
      }
      return "close";
    },
  },
  methods: {
    closeHandle() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.xw-earnings {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 111;
  overflow-y: auto;
}

.slot {
  margin-top: 90px;
}

.close {
  width: 31px;
  margin: 20px auto;
}
.topclose {
  width: 31px;
  margin: 20px auto;
}

.fadeIn {
  animation: popIn 0.3s;
}

@keyframes popIn {
  0% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0.5, 0.5, 0.5);
    opacity: 0;
  }
  50% {
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
  }
}
</style>
